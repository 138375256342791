<template>
  <div class="customer-detail">
    <el-page-header :title="customer.name"
                   :sub-title="'创建日期:'+new Date(customer.createdAt * 1000).Format('yyyy-MM-dd')"
                   @back="() => $router.go(-1)">
      <template slot="tags">
        <el-tag color="blue">
          {{customer.sortName}}
        </el-tag>
      </template>
      <template slot="extra">

        <el-button key="1" type="link">
          <my-icon type="iconsetting"></my-icon>
          信息设置
        </el-button>
        <el-button key="2" type="link" class="delete">
          <my-icon type="iconshanchu"></my-icon>
          客户停用
        </el-button>
      </template>
    </el-page-header>
    <div class="customer-detail-content">
      <p class="customer-detail-tip">「测试设备」于2020-09-16 10:08:10 产生离线停机预警</p>

      <el-row type="flex" justify="space-around" align="middle" class="content-basic-info">
        <el-col :span="10">
          <el-row type="flex" justify="space-around" align="top">
            <el-statistic title="本月订单" :value="800">
              <template #suffix>
                <div class="content-basic-info-value">
                  <p>销售订单:778 </p>
                  <p>退款订单:12</p>
                </div>
              </template>
            </el-statistic>
            <el-statistic title="本月交易额(元)" prefix="¥" :value="3345.08">
              <template #suffix>
                <div class="content-basic-info-value">
                  <p>实收金额:3225.08 </p>
                  <p>退款金额:120</p>
                </div>
              </template>
            </el-statistic>
            <el-statistic
              title="客户状态"
              :value="customer.status"
              :style="{
            margin: '0 32px',
          }"
            />
          </el-row>
        </el-col>
        <el-col :span="14" class="basic-info">
          <p class="basic-info-item">
            <span class="label">联系人/联系方式:</span>
            <span class="value">{{customer.contactName}}/{{customer.contactPhone}}</span>
          </p>
          <p class="basic-info-item">
            <span class="label">客户地址:</span>
            <span class="value">{{customer.address}}</span>
          </p>
          <p class="basic-info-item">
            <span class="label">客户标签:</span>
            <el-tag color="blue" v-for="(tag, _t) in customer.tags" :key="_t">{{tag.name}}</el-tag>
          </p>
          <div class="basic-info-item">
            <span class="label">可用支付:</span>
            <my-icon class="logo" :type="'iconzhifubao'" :style="{fontSize: '24px', color: '#027AFF'}"></my-icon>
            <my-icon class="logo" :type="'iconweixinzhifu'" :style="{fontSize: '24px', color: '#1AAD19'}"></my-icon>
          </div>
          <el-row class="basic-info-item">
            <el-col :span="6">
              <div>
                <p>套餐总数</p>
                <p class="number">3</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <p>可用设备</p>
                <p class="number">3</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <p>本月异常停机</p>
                <p class="number">5</p>
              </div>
            </el-col>
            <el-col :span="6">
              <div>
                <p>本月期望预警</p>
                <p class="number">5</p>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <p class="title">
        <span class="title-name">物料状况</span>
        <span class="title-tip">(物料与套餐饮品自动关联,如需修改请至「业务套餐」板块修改饮品)</span>
        <el-button type="link">修改物料</el-button>
      </p>
      <el-row class="customer-detail-material">
        <el-col v-for="(material, idx) in materials" :key="idx" :span="8">
          <div class="material-item">
            <div class="material-item-progress">


              <el-progress type="circle" :width="80" :percent="100"
                          status="exception" v-if="material.rest === 0 || material.total=== 0"/>
              <el-progress type="circle"
                          :width="80"
                          :percent="parseInt((material.total - material.rest)  * 100 / material.total) "
                          v-else/>
              <p class="text">用量进度</p>
            </div>

            <div>
              <p><span class="material-item-name">{{material.name}}</span></p>
              <p>
                <span class="material-item-label">累计派送: </span>
                <span class="material-item-value">{{material.total}} {{material.unitTxt}}</span>
              </p>
              <p>
                <span class="material-item-label">剩余用量: </span>
                <span class="material-item-value">{{material.rest}} {{material.unitTxt}}</span>
              </p>
              <p>
                <span class="material-item-label">预计可用: </span>
                <span class="material-item-value">{{material.restDay}}天</span>
              </p>
              <p>
                <span class="material-item-label">物料建议: </span>
                <span class="material-item-value">{{material.advertise}}</span>
              </p>
            </div>

            <el-button type="link" class="material-item-add">补充物料</el-button>
          </div>
        </el-col>

      </el-row>
      <el-tabs v-model="defaultColumn">
        <el-tab-pane :key="col.value" :tab="col.name" v-for="(col) in column">
          <component v-bind:is="col.value"></component>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'customer-detail',
    components: {
      combination: function (resolve) {
        require(["./combination.vue"], resolve);
      },
      order: function (resolve) {
        require(["./order.vue"], resolve);
      },
      device: function (resolve) {
        require(["./device.vue"], resolve);
      },
      free: function (resolve) {
        require(["./free.vue"], resolve);
      },
      alarm: function (resolve) {
        require(["./alarm.vue"], resolve);
      },
      operate: function (resolve) {
        require(["./operate.vue"], resolve);
      },
      material: function (resolve) {
        require(["./material.vue"], resolve);
      },
    },
    mounted: function () {
    },
    data: function () {
      return {
        customer: {
          name: '苏州智能科技有限公司',
          createdAt: 1605794986,
          sortName: '成交客户',
          address: '江苏省苏州市工业园区钟南街',
          status: '建议跟进',
          contactName: '张三',
          contactPhone: '18500001234',
          tags: [
            {
              name: '大客户',
            },
            {
              name: '单台设备每周平均低于40杯预警',
            }
          ]
        },
        column: [
          {name: '客户套餐', value: 'combination'},
          {name: '制作订单', value: 'order'},
          {name: '可用设备', value: 'device'},
          {name: '免单人员', value: 'free'},
          {name: '物料记录', value: 'material'},
          {name: '预警明细', value: 'alarm'},
          {name: '操作日志', value: 'operate'},
        ],
        defaultColumn: 'combination',
        materials: [
          {
            name: '天际咖啡豆',
            unitTxt: '包',
            total: 35,
            rest: 10,
            restDay: 10,
            advertise: '物料充足',
          },
          {
            name: '光明调剂奶',
            unitTxt: '盒',
            total: 10,
            rest: 2,
            restDay: 5,
            advertise: '建议备料',
          },
          {
            name: '巧克力粉',
            unitTxt: '袋',
            total: 10,
            rest: 1,
            restDay: 2,
            advertise: '尽快备料',
          },
          {
            name: '抹茶粉',
            unitTxt: '袋',
            total: 10,
            rest: 0,
            restDay: 0,
            advertise: '无料可用',
          }
        ]
      }
    },
    methods: {},
  }
</script>

<style lang="scss" scoped>
  .customer-detail {
    &-material {
      margin-bottom: $middle-space;

      .material-item-operate {
        text-align: center;
        justify-content: center;
        cursor: pointer;
        color: $theme-color;

      }

      .material-item {
        display: flex;
        justify-items: center;
        align-items: center;
        height: 136px;
        @include box-shadow();
        border-radius: $box-radius;
        margin: 0 $small-space;
        position: relative;
        margin-bottom: $small-space;

        &-progress {
          margin: 0 $middle-space;
          text-align: center;

          .text {
            @include font-little-h();
            margin-top: $small-space;
          }
        }

        &-name {
          color: $color-black;
          @include font-medium();
        }

        &-label {
          @include font-little-h();
          display: inline-block;
          margin-right: $small-space;
        }

        &-value {
          color: $color-black;
        }

        &-add {
          position: absolute;
          right: 0;
          top: $small-space;
        }
      }
    }

    .title {
      margin: $small-space;

      &-name {
        font-weight: 500;
      }

      &-tip {
        @include font-little();
      }
    }

    &-content {
      margin: 0 $container-padding;
      min-height: 968px;

      .content-basic-info {
        .basic-info {
          &-item {
            margin-bottom: $small-space;

            .value {
              color: $color-black;
            }

            .label {
              display: inline-block;
              margin-right: $small-space;
              @include font-little-h();
            }

            .logo {
              margin-right: $small-space;
            }

            .number {
              @include font-large-s();
              margin-left: $middle-space;
            }

          }
        }

        &-value {
          @include font-little-h();
        }
      }

       .ant-statistic-content-suffix {
        display: block;
      }
    }

    &-tip {
      width: 100%;
      border: 1px solid $brand-warning;
      background-color: $three-light-9;
      color: $brand-warning;
      font-size: $font-little;
      height: 24px;
      line-height: 24px;
      margin-bottom: $middle-space;
      cursor: pointer;
    }

    &-tip:hover {
      background-color: $brand-warning;
      color: $color-white;;
    }

    .delete {
      color: $brand-danger;
    }
  }
</style>
